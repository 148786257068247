import { useEffect } from "react"

const useHideStaffOnlyPinkBoxes = () => {
  useEffect(() => {
    if (window.currentPerson.impersonating) {
      const pcoStaffUrlsTopBarIsHidden =
        document.querySelectorAll(".pco_staff_urls_top_bar--is-hidden").length >
        0
      if (pcoStaffUrlsTopBarIsHidden) {
        window.PCOStaffUrls.hideStaffOnlyPinkBoxes()
      }
    }
  })
}

export default useHideStaffOnlyPinkBoxes
