import { useEffect, useRef, useState } from "react"
import SearchInput from "components/search_input"
/**
 * @typedef UseFilterBySearchPhraseHook
 * @property {string} SearchFilterInput - React component for rendering the search input ui
 * @property {string} searchFilterInputProps - Props that should be spread into SearchFilterInput component
 * @property {string} searchPhrase - search phrase that's currently active in the input
 * @property {string} resetSearchFilter - function that resets the search phrase to empty
 */

/**
 * useFilterBySearchPhrase - custom hook that wires up a search-styled Input with a debouncer, handles input business logic internally
 * @param {Object} config
 * @param {string} config.placeholder - a string to display when search input is empty
 * @param {number} [config.debounceTimeoutMilliseconds=300] - the number of milliseconds to wait in between key strokes before updating searchPhrase
 * @returns {UseFilterBySearchPhraseHook}
 */
export default function useFilterBySearchPhrase({
  placeholder,
  debounceTimeoutMilliseconds = 300,
}) {
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState("")
  const [searchPhrase, setSearchPhrase] = useState("")
  const debounceTimeoutId = useRef(null)
  const searchFilterInputRef = useRef()

  useEffect(() => {
    if (debounceTimeoutId.current) {
      clearTimeout(debounceTimeoutId.current)
    }

    debounceTimeoutId.current = setTimeout(() => {
      setDebouncedSearchPhrase(searchPhrase)
    }, debounceTimeoutMilliseconds)
  }, [debounceTimeoutMilliseconds, searchPhrase])

  function handleChange({ target: { value } }) {
    setSearchPhrase(value)
  }

  function resetSearchFilter() {
    setDebouncedSearchPhrase("")
    setSearchPhrase("")
    searchFilterInputRef.current?.focus?.()
  }

  const searchFilterInputProps = {
    placeholder,
    onChange: handleChange,
    resetSearchFilter,
    value: searchPhrase,
    ref: searchFilterInputRef,
  }

  return {
    SearchFilterInput: SearchInput,
    searchFilterInputProps,
    searchPhrase: debouncedSearchPhrase,
    resetSearchFilter,
  }
}
