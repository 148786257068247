import React from "react"

const SvgForms = () => (
  <svg
    id="forms_blank"
    data-name="Forms Blank"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 111.112 84.832"
    className="symbol"
    aria-label="No forms"
    role="img"
    focusable="no"
  >
    <title>people-forms</title>
    <g opacity="0.6">
      <path
        d="M107.607,46.051A57.416,57.416,0,0,1,85.083,75.058c-9.7,6.247-20.046,7.792-31.912,5.185-11.74-2.638-23.339-8-32.921-13.088-9.509-5.342-17-10.236-18.862-17.925C-.437,41.753,3.559,31.4,13.17,21.194c9.5-10.032,24.621-19.341,42.6-20.873,17.85-1.8,34.253,4.157,43.66,12.843C109.08,21.9,111.744,33.784,107.607,46.051Z"
        fill="#e1f0f9"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M62.908,84.317A43.515,43.515,0,0,1,42.693,79c-8.814-4.618-18.159-11.575-28.572-21.269C6.439,50.227.107,43.348.311,35c.131-5.851,3.79-12.312,10.3-18.189l.951,1.054C5.345,23.475,1.852,29.572,1.73,35.031,1.54,42.811,7.673,49.448,15.1,56.7c10.307,9.6,19.551,16.481,28.248,21.039,9.043,4.684,17.715,6.156,26.512,4.5l.263,1.4A38.917,38.917,0,0,1,62.908,84.317Z"
        fill="#bac7d3"
      />
      <path
        d="M80.927,80.147l-.586-1.292a56.6,56.6,0,0,0,23.515-19.828l1.183.786A58.026,58.026,0,0,1,80.927,80.147Z"
        fill="#bac7d3"
      />
      <path
        d="M109.848,49.27l-1.374-.356a28.351,28.351,0,0,0-4.014-22.991C97.011,14.135,82.31,5.177,66.09,2.538A66.6,66.6,0,0,0,25.561,8.55l-.622-1.275a68,68,0,0,1,41.39-6.136c16.6,2.7,31.672,11.905,39.327,24.019A29.736,29.736,0,0,1,109.848,49.27Z"
        fill="#bac7d3"
      />
    </g>
    <path
      d="M80.206,31.128v31.8a2.685,2.685,0,0,1-2.685,2.685H43.767a2.685,2.685,0,0,1-2.685-2.685V19.284A2.685,2.685,0,0,1,43.767,16.6h21.91a2.685,2.685,0,0,1,1.9.786L79.42,29.23A2.685,2.685,0,0,1,80.206,31.128Z"
      fill="#fff"
    />
    <path
      d="M67.682,17.492v9.8a2.035,2.035,0,0,0,2.035,2.034h9.877Z"
      fill="#c9d8e2"
    />
    <path
      d="M73.187,38.025H56.754a1.5,1.5,0,0,1,0-3H73.187a1.5,1.5,0,0,1,0,3Z"
      fill="#dae5ed"
    />
    <path
      d="M73.187,45.148H56.754a1.5,1.5,0,0,1,0-3H73.187a1.5,1.5,0,0,1,0,3Z"
      fill="#dae5ed"
    />
    <path
      d="M73.187,52.27H56.754a1.5,1.5,0,0,1,0-3H73.187a1.5,1.5,0,0,1,0,3Z"
      fill="#dae5ed"
    />
    <path
      d="M48.455,38.2l-1.264-1.264a.87.87,0,0,1,0-1.231h0a.872.872,0,0,1,1.232,0l.652.652,2.217-2.217a.87.87,0,0,1,1.231,0h0a.872.872,0,0,1,0,1.232L49.7,38.2A.876.876,0,0,1,48.455,38.2Z"
      fill="#48a5e5"
    />
    <path
      d="M48.455,45.59l-1.264-1.264a.87.87,0,0,1,0-1.231h0a.872.872,0,0,1,1.232,0l.652.653,2.217-2.217a.87.87,0,0,1,1.231,0h0a.87.87,0,0,1,0,1.231L49.7,45.59A.878.878,0,0,1,48.455,45.59Z"
      fill="#48a5e5"
    />
    <path
      d="M48.455,52.98l-1.264-1.264a.872.872,0,0,1,0-1.232h0a.872.872,0,0,1,1.232,0l.652.652,2.217-2.216a.87.87,0,0,1,1.231,0h0a.87.87,0,0,1,0,1.231L49.7,52.98A.878.878,0,0,1,48.455,52.98Z"
      fill="#48a5e5"
    />
    <rect
      x="62.735"
      y="41.012"
      width="21.043"
      height="5.464"
      transform="translate(-12.06 44.782) rotate(-31.54)"
      fill="#fcc846"
    />
    <path
      d="M81.886,34.241h2.027a2.732,2.732,0,0,1,2.732,2.732v0A2.732,2.732,0,0,1,83.913,39.7H81.886a0,0,0,0,1,0,0V34.241A0,0,0,0,1,81.886,34.241Z"
      transform="translate(-6.901 49.474) rotate(-31.498)"
      fill="#f65f34"
    />
    <polygon
      points="65.718 51.579 60.373 51.567 62.862 46.918 65.718 51.579"
      fill="#f7deb0"
    />
    <polygon
      points="61.09 50.182 61.954 51.584 60.346 51.579 61.09 50.182"
      fill="#373737"
    />
  </svg>
)

export default SvgForms
