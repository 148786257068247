import Filter from "components/dropdown_filter"
import { useState } from "react"

/**
 * @typedef UseFilterByCollectionItemHook
 * @property {function} Filter - React component for rendering the filter ui for the collection
 * @property {Object} filterProps - Props that should be spread into Filter component
 * @property {Object} selectedItem - the item from the collection that's currently selected
 * @property {number} selectedItemId - the ID of the selectedItem
 * @property {function} resetFilter - a funcction that resets the selectedItem and ID back to null
 * @property {string} setSelectedItem - a function to set the selected item manually from outside the Filter component
 */

/**
 * useFilterByCollectionItem - custom hook that facilitates storing and retrieving a user
 *  selection from a collection of items
 *
 * @param {Object} config
 * @param {string} config.localStorageKey - the key for storing this value in browser localStorage
 * @param {Object[]} config.collection - array of items from which a selection will be made
 * @param {?*} config.queryId - the ID of the item in the query string
 * @param {function} config.onSelect - a function that gets called when a new selection is made
 * Any additional props are passed through to Filter component
 *
 * @returns {UseFilterByCollectionItemHook}
 */
export default function useFilterByCollectionItem({
  localStorageKey,
  collection,
  onSelect,
  queryId,
  ...additionalFilterProps
}) {
  const [selectedItem, setSelectedItem] = useState(() => {
    if (queryId) {
      const defaultValue = collection.find(item => item.id === queryId)
      if (defaultValue) {
        localStorage.setItem(localStorageKey, queryId)
        return defaultValue
      }
    }

    const storedId = localStorage.getItem(localStorageKey)
    if (!storedId) return null

    const defaultValue = collection.find(
      item => parseInt(item.id) === parseInt(storedId)
    )
    if (!defaultValue) {
      localStorage.removeItem(localStorageKey)
      return null
    }

    return defaultValue
  })
  const selectedItemId = selectedItem?.id

  function setSelectedItemAndExecuteCallback(item) {
    if (!item) {
      localStorage.removeItem(localStorageKey)
    } else {
      localStorage.setItem(localStorageKey, item.id)
    }

    setSelectedItem(item)
    onSelect?.(item)
  }

  function handleFilteredItemChange(item) {
    setSelectedItemAndExecuteCallback(item)
  }

  function resetFilter() {
    setSelectedItemAndExecuteCallback(null)
  }

  const filterProps = {
    active: selectedItem,
    collection,
    onSelect: handleFilteredItemChange,
    ...additionalFilterProps,
  }

  return {
    Filter,
    filterProps,
    selectedItem,
    selectedItemId,
    resetFilter,
    setSelectedItem: setSelectedItemAndExecuteCallback,
  }
}
