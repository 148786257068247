import useApi from "hooks/use_api"
import getIncludedItem from "utils/get_included_item"

export default function useForms({
  campusId = null,
  categoryId = null,
  filter = null,
  initialOffset = 0,
  perPage = 30,
  searchPhrase = null,
  order = "name",
}) {
  const {
    response: formsApiResponse,
    loading,
    ...apiProps
  } = useApi({
    method: "get",
    path:
      filter && filter.includes("recently_viewed")
        ? `/people/v2/people/${window.currentPerson.id}/recently_viewed_forms`
        : "/people/v2/forms",
    params: {
      order,
      include: "category,campus",
      filter,
      "where[category_id]": categoryId,
      "where[campus_id]": campusId,
      "where[name]": searchPhrase,
    },
    perPage,
    initialOffset,
  })

  let forms,
    totalUnfilteredCount,
    totalFilteredCount,
    totalRecoverableCount,
    totalRecentlyViewedCount,
    totalOpenCount,
    totalClosedCount,
    totalArchivedCount,
    hasForms,
    currentPersonCanManageAnyDeletedForms

  if (typeof formsApiResponse?.data !== "undefined") {
    const { data, included, meta } = formsApiResponse
    forms = data.map(form => {
      form.campus = getIncludedItem(included, form?.relationships?.campus?.data)
      form.category = getIncludedItem(
        included,
        form?.relationships?.category?.data
      )
      return form
    })

    totalFilteredCount = meta?.total_count ?? 0
    totalRecoverableCount = meta?.total_recoverable_count ?? 0
    totalRecentlyViewedCount = meta?.total_recently_viewed_count ?? 0
    totalOpenCount = meta?.total_open_count
    totalClosedCount = meta?.total_closed_count
    totalArchivedCount = meta?.total_archived_count
    hasForms = meta?.has_forms ?? false
    totalUnfilteredCount = meta?.total_unfiltered_count ?? 0
    currentPersonCanManageAnyDeletedForms = totalRecoverableCount > 0
  }

  let status
  if (loading) {
    status = "loading"
  } else if (!hasForms) {
    status = "blank_state"
  } else if (totalFilteredCount === 0) {
    status = "no_matching_forms"
  } else {
    status = "forms_list"
  }

  return {
    ...apiProps,
    status,
    forms,
    totalUnfilteredCount,
    totalFilteredCount,
    totalRecoverableCount,
    totalRecentlyViewedCount,
    totalOpenCount,
    totalClosedCount,
    totalArchivedCount,
    currentPersonCanManageAnyDeletedForms,
  }
}
