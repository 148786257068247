import { useEffect, useState } from "react"

// Adapted from https://usehooks.com/useLocalStorage
function useLocalStorage(key, initialValue, overwriteWithInitialValue = false) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (overwriteWithInitialValue) return initialValue

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (_error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Reflect the state into localStorage as a side effect.
  const localStorageRepresentation = JSON.stringify(storedValue)
  useEffect(() => {
    window.localStorage.setItem(key, localStorageRepresentation)
  }, [key, localStorageRepresentation])

  // When we are done with this key, drop it from localStorage completely
  const dropKey = () => window.localStorage.removeItem(key)

  return [storedValue, setStoredValue, dropKey]
}

export default useLocalStorage
