import React from "react"
import { Spinner, Summary } from "@planningcenter/tapestry-react"

export default function LoadingView({ label }) {
  return (
    <Summary
      image={<Spinner size="xl" />}
      title={label}
      mediaQueries={{
        lg: {
          paddingVertical: "20vh", // vertical spacing for larger displays
        },
      }}
    />
  )
}
