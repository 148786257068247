import SidebarFilterButtonGroup from "components/sidebar_filter_button_group"
import useLocalStorage from "hooks/use_local_storage"

/**
 * @typedef Filter
 * @property {string} filterValue - the name of the filter
 * @property {string} icon - the name of the icon to render for this filter button
 * @property {string} title - the title to render for this filter button
 * @property {(number|Function)} count - the number of items matching the filterValue, or a function that returns the count
 */

/**
 * @typedef UseSidebarFiltersHook
 * @property {function} SidebarFilterButtonGroup - React component for rendering sidebar filter button group ui
 * @property {Object} sidebarFilterButtonGroupProps - Props that should be spread into SidebarFilterButtonGroup component
 * @property {string} selectedFilter - the name of the selected filter
 * @property {function} resetFilter - function that resets selected filter to initial value
 */

/**
 * useSidebarFilters - custom hook that returns UI for SidebarFilterButtonGroup and handles logic for persisting selections in local storage
 * @param {Object} config
 * @param {Filter[]} config.filters - an array of Filters used to render filter buttons
 * @param {string} config.localStorageKey - the key for storing this value in browser localStorage
 * @param {*} config.initialValue - the initial Filter.filterValue to set as selectedFilter
 * @param {function} config.onSelect - a function that gets called when a new selection is made
 * @param {boolean} config.overwriteWithInitialValue - set to true to overwrite current local storage value with initialValue
 * @returns {UseSidebarFiltersHook}
 */
export default function useSidebarFilters({
  filters = [],
  localStorageKey,
  initialValue,
  onSelect,
  overwriteWithInitialValue = false,
}) {
  if (typeof localStorageKey !== "string") {
    throw new Error(
      "You must provide a `localStorageKey` for `useSidebarFilters`."
    )
  }

  const [selectedFilter, setSelectedFilter] = useLocalStorage(
    localStorageKey,
    initialValue,
    overwriteWithInitialValue
  )

  function setSelectedFilterAndExecuteCallback(newFilterValue) {
    setSelectedFilter(newFilterValue)
    onSelect?.(newFilterValue)
  }

  function resetFilter() {
    setSelectedFilterAndExecuteCallback(null)
  }

  const sidebarFilterButtonGroupProps = {
    filters,
    selectedFilter,
    onFilterChange: setSelectedFilterAndExecuteCallback,
  }

  return {
    SidebarFilterButtonGroup,
    sidebarFilterButtonGroupProps,
    selectedFilter,
    resetFilter,
  }
}
