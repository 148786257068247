import { useCallback, useEffect, useReducer } from "react"
import api from "utils/api"
import { isEqual } from "lodash"
import { Pagination } from "@planningcenter/tapestry-react"

function responseReducer(state, action) {
  return {
    ...state,
    ...action,
  }
}

function addSignalToAdditionalMethodArgs(additionalMethodArgs, signal) {
  const copyOfArgs = [...additionalMethodArgs]

  if (typeof copyOfArgs[0] === "object" && copyOfArgs[0] !== null) {
    copyOfArgs[0].signal = signal
  } else {
    copyOfArgs.unshift({ signal })
  }

  return copyOfArgs
}

function apiFetchFunction(config) {
  let abort = () => {}
  let { additionalMethodArgs } = config

  if (["get", "patch", "post"].includes(config.method)) {
    const abortController = new AbortController()
    abort = () => abortController.abort()
    additionalMethodArgs = addSignalToAdditionalMethodArgs(
      additionalMethodArgs,
      abortController.signal
    )
  }

  return {
    abort,
    execute: async () =>
      api[config.method]
        .apply(this, [
          config.path,
          {
            ...config.params,
            per_page: config.perPage,
            offset: config.usePaging ? config.offset : null,
          },
          ...additionalMethodArgs,
        ])
        .catch(e => {
          if (e.name !== "AbortError") throw e
        }),
  }
}

function methodReducer(state, action) {
  let config = {
    ...state.config,
    ...action,
  }

  const hasChanged = !isEqual(state.config, config)
  const isValid = Boolean(config?.method && config?.path)
  const hasExecute = typeof state?.execute === "function"

  if (isValid && (!hasExecute || hasChanged)) {
    config = {
      ...config,
      offset: 0,
      ...action,
    }

    return {
      config,
      abortPrevious: state?.abort ?? (() => {}),
      ...apiFetchFunction(config),
    }
  } else return state
}

/**
 * The return value for a typical API fetch call
 *
 * @typedef ApiResponse
 * @property {Array} data
 * @property {Array} included
 * @property {Object} links
 * @property {?Object} meta - meta is not returned for all utils/api methods
 */

/**
 * The return value for the useWorkflows hook
 *
 * @typedef {Object} UseApiHook
 * @property {function} refresh - Function that can be called to invoke method again
 * @property {function} abort - Function to cancel the API request
 * @property {function} Pagination - React component for rendering pagination ui
 * @property {Object} paginationProps - Props that should be spread into Pagination component
 * @property {number} totalItemCount
 * @property {ApiResponse} response - response from the PCO API
 * @property {boolean} loading
 */

/**
 * useApi - custom hook for fetching data from API
 *
 * @param {Object} config
 * @param {(get|getAll|patch|post|delete)} config.method - the method from utils/api class that you want to invoke
 * @param {string} config.path - the URL path within the PCO API you want to request; passed as first argument to method
 * @param {Object} config.params - the API params to pass along to the PCO API when requesting; passed as the second argument to method
 * @param {Array} [config.additionalMethodArgs = []] - any additional arguments to pass to the utils/api method; spread as the remaining arguments to method
 * @param {*} [config.initialValue = {}] - the initial value to set in state prior to the API call being invoked
 * @param {number} [config.perPage = 30] - the number of items per page to request from the API
 * @param {number} [config.initialOffset = 0] - the offset to pass to the API on the first invocation of method
 * @param {boolean} [config.usePaging = true] - when true, this hook will control paging offset; otherwise, offset will not be sent
 * @returns {UseApiHook}
 */
export default function useApi({
  method,
  path,
  params,
  additionalMethodArgs = [],
  initialValue = {},
  perPage = 30,
  initialOffset = 0,
  usePaging = true,
}) {
  const [methodState, setMethodConfig] = useReducer(methodReducer, {
    config: {
      method,
      path,
      params,
      additionalMethodArgs,
      offset: initialOffset,
      perPage,
      usePaging,
    },
  })

  const [responseState, setResponseState] = useReducer(responseReducer, {
    response: initialValue,
    loading: true,
    totalItemCount: 0,
  })

  const loadData = useCallback(async () => {
    if (typeof methodState.execute !== "function") return

    setResponseState({ loading: true })

    // Cancel any pending API calls
    if (typeof methodState.abortPrevious === "function") {
      methodState.abortPrevious()
    }

    const response = await methodState.execute()

    if (response) {
      setResponseState({
        loading: false,
        response,
        totalItemCount: response?.meta?.total_count || 0,
      })
    }
  }, [methodState])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    setMethodConfig({
      method,
      path,
      perPage,
      usePaging,
      params,
      additionalMethodArgs,
    })
  }, [additionalMethodArgs, method, params, path, perPage, usePaging])

  const paginationProps = {
    onPageChange: pageNumber => {
      setMethodConfig({ offset: methodState.config.perPage * (pageNumber - 1) })
    },
    currentPage:
      Math.floor(methodState.config.offset / methodState.config.perPage) + 1,
    totalPages: Math.ceil(
      responseState.totalItemCount / methodState.config.perPage
    ),
  }

  return {
    refresh: loadData,
    abort: methodState.abort,
    Pagination:
      usePaging && responseState.totalItemCount > methodState.config.perPage
        ? Pagination
        : () => null,
    paginationProps: usePaging ? paginationProps : {},
    ...responseState,
  }
}
