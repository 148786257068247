import moment from "moment"

const HIDDEN_DATE_YEAR = 1885

export const getFormattedDateTime = date => {
  const dateString =
    window.currentOrganization.dateFormat === "%m/%d/%Y"
      ? "MMM D, Y"
      : "D MMM Y"
  const timeString = window.currentOrganization.twentyFourHourTime
    ? "HH:mm"
    : "h:mma"
  const tzString = moment(date)
    .tz(window.currentOrganization.olsonTimeZone)
    .format("z")

  return moment(date).format(
    `${dateString} [at] ${timeString}[&nbsp;${tzString}]`
  )
}

export const jqueryDateFormat = format => {
  return format
    .replace(/%m/, "mm")
    .replace(/%d/, "dd")
    .replace(/%Y/, "yy")
    .replace(/%y/, "y")
}

export const momentDateFormat = format => {
  return format
    .replace(/%m/, "MM")
    .replace(/%d/, "DD")
    .replace(/%Y/, "YYYY")
    .replace(/%y/, "YY")
}

export const dateFNSFormat = format => {
  return format
    .replace(/%m/, "M")
    .replace(/%d/, "d")
    .replace(/%Y/, "y")
    .replace(/%y/, "yy")
}

export const momentDateTimeFormat = ({ dateFormat, twentyFourHourTime }) => {
  return `${momentDateFormat(dateFormat)} ${
    twentyFourHourTime ? "HH:mm" : "h:mm A"
  }`
}

export const dateLocalized = date => {
  const momentDate = moment(date, "YYYY-MM-DD")
  if (!momentDate.isValid()) {
    return ""
  }

  const format = momentDateFormat(window.currentOrganization.dateFormat)
  const formattedDate = momentDate.format(format)
  const year = momentDate.year()

  if (year === HIDDEN_DATE_YEAR) {
    return formattedDate.slice(0, -5)
  } else {
    return formattedDate
  }
}

export const dateInternationalized = date => {
  const format = momentDateFormat(window.currentOrganization.dateFormat)
  const momentDate = moment(date, format)
  if (momentDate.isValid()) {
    return momentDate.format("YYYY-MM-DD")
  } else {
    return ""
  }
}

export const dayLocalized = date => {
  const momentDate = moment(date)
  const dayFormat = momentDateFormat(window.currentOrganization.dateFormat)
    .replaceAll("/", " ")
    .replace("YYYY", "")
    .replace("MM", "MMM")
    .replace("DD", "Do")
  return momentDate.format(dayFormat)
}

export const ageString = date => {
  if (!date) return ""

  const momentDate = moment(date)
  const year = momentDate.year()

  if (year === HIDDEN_DATE_YEAR) return ""

  const months = moment().diff(momentDate, "months")

  if (months < 0) {
    return ""
  }

  if (months <= 18) {
    return `${months} months`
  } else {
    return `${Math.floor(months / 12)} years`
  }
}
