import React from "react"
import { Badge, Box, Button } from "@planningcenter/tapestry-react"

/**
 * @param {Object} props
 * @param {string} props.icon - name of icon to display on button, must also be imported into tapestry theme
 * @param {boolean} props.selected - when true, renders a variant that cannot be clicked
 * @param {number|function} props.count - the number of items matching this filter, or a function that returns the count (for lazy loading)
 * @param {string} props.title - the text label to display on the button
 * @param {function} props.onClick - function to be called when button is clicked
 * @param {function} props.generateAdditionalProps - function that given two arguments: selected, count - returns an object with two keys: button, badge - with additional props to apply to the button and badge subcomponents
 *
 * Any additional props supplied will be passed through to the Button component
 */
export default function SidebarFilterButton({
  icon,
  selected,
  count,
  title,
  onClick,
  generateAdditionalProps,
  ...props
}) {
  const additionalProps = generateAdditionalProps?.({
    selected,
    count: count?.() ?? count,
  })

  return (
    <Box
      css={{
        ":hover": !selected
          ? {
              svg: { fill: "#fff" },
              ".filter-count": { backgroundColor: "base2", color: "#fff" },
            }
          : {},
      }}
    >
      <Button
        iconLeft={{
          name: icon,
        }}
        variant={selected ? "fill" : "naked"}
        theme="sidebarFilter"
        onClick={selected ? null : onClick}
        width="100%"
        {...props}
        {...additionalProps?.button}
      >
        <>
          <div style={{ flex: 1, display: "flex" }}>{title}</div>
          {typeof count !== "undefined" ? (
            <Badge
              className="filter-count"
              css={{
                color: "inherit",
                padding: "0 8px",
                pointerEvents: "none",
                backgroundColor: selected
                  ? "var(--t-text-color-default-inverted)"
                  : "var(--t-fill-color-status-info-ghost)",
              }}
              radius="pill"
              size="xs"
              title={typeof count === "function" ? count() : count}
              {...additionalProps?.badge}
            />
          ) : null}
        </>
      </Button>
    </Box>
  )
}
