import React from "react"
import { StackView } from "@planningcenter/tapestry-react"
import SidebarFilterButton from "components/sidebar_filter_button"

/**
 * @typedef {Object} Filter
 * @property {string} filterValue - the name of the filter, a value from response.meta.can_filter
 *
 * Any remaining props pass through to the SidebarFilterButton component
 */

/**
 * Renders a list of SidebarFilterButtons given an array of filter options
 *
 * @param {Object} props
 * @param {Filter[]} props.filters
 * @param {string} props.selectedFilter - the name of the filter that's currently selected, a value from response.meta.can_filter
 * @param {function} props.onFilterChange - the function to be called when the selected filter changes as a result of a user clicking a SidebarFilterButton
 */
export default function SidebarFilterButtonGroup({
  filters = [],
  selectedFilter,
  onFilterChange,
}) {
  function handleFilterChange(filterValue) {
    return () => onFilterChange(filterValue)
  }

  return (
    <StackView
      role="group"
      spacing={1}
      marginTop={2}
      mediaQueries={{ lg: { spacing: 0 } }}
    >
      {filters.map(({ filterValue, ...buttonProps }) => (
        <SidebarFilterButton
          key={filterValue}
          selected={selectedFilter === filterValue}
          onClick={handleFilterChange(filterValue)}
          {...buttonProps}
        />
      ))}
    </StackView>
  )
}
