/* a simple search field with a magnifying glass icon
 *
 * <SearchInput
 *   value={''}
 *   onChange={(e) => console.log(e.target.value)} />
 */

import React, { forwardRef, useImperativeHandle, useRef } from "react"
import PropTypes from "prop-types"
import { Button, Icon, Input } from "@planningcenter/tapestry-react"

const SearchInput = forwardRef(
  ({ onChange: handleChange, resetSearchFilter, value, placeholder }, ref) => {
    const inputRef = useRef(null)

    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus?.(),
    }))

    return (
      <Input
        size="md"
        placeholder={placeholder}
        aria-label={placeholder}
        autoComplete="off"
        onChange={handleChange}
        value={value}
        paddingVertical={0.75}
        ref={inputRef}
        renderRight={
          value ? (
            <Button
              variant="naked"
              icon={{ name: value ? "general.x" : "general.search" }}
              tooltip={{ title: "Search" }}
              onClick={resetSearchFilter}
              aria-label="Clear Search"
              size="sm"
            />
          ) : (
            <Icon name="general.search" aria-label="search icon" />
          )
        }
      />
    )
  }
)

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  resetSearchFilter: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

SearchInput.defaultProps = {
  placeholder: "Search",
}

export default SearchInput
