import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

// key names match FormField
export const SETTINGS = {
  adults: {
    phone_number: "Phone number",
    gender: "Gender",
    birthday: "Birthdate",
    marital_status: "Marital status",
    anniversary: "Anniversary",
  },
  children: {
    gender: "Gender",
    birthday: "Birthdate",
    grade: "Grade",
    school: "School",
    medical: "Medical notes",
  },
}

export default props =>
  Object.entries(SETTINGS).map(([group, settings]) => (
    <div className="mt-3" key={group}>
      <h4 className="mb-2p">Collect for {group}</h4>
      <p className="mb-1!">
        Name {group === "adults" ? "and email address are" : "is"} always
        collected.
      </p>
      <table>
        <thead>
          <tr>
            <th> </th>
            <th className="tt-u fs-6">Required</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(settings).map(([setting, label]) => (
            <CollectionField
              {...props}
              key={`${group}-${setting}`}
              group={group}
              setting={setting}
              label={label}
            />
          ))}
        </tbody>
      </table>
    </div>
  ))

const CollectionField = ({
  group,
  setting,
  label,
  settings,
  onSettingsChange,
}) => (
  <tr>
    <td className="pl-3 pr-5">
      <input
        type="checkbox"
        className="checkbox"
        id={`${group}-${setting}`}
        checked={
          (settings[group] &&
            settings[group][setting] &&
            settings[group][setting].collect) ||
          false
        }
        onChange={() =>
          onSettingsChange({
            ...settings,
            [group]: {
              ...settings[group],
              [setting]: {
                collect:
                  settings[group] && settings[group][setting]
                    ? !settings[group][setting].collect
                    : true,
              },
            },
          })
        }
      />
      <label className="checkbox-label" htmlFor={`${group}-${setting}`}>
        {label}
      </label>
    </td>
    <td className="p-r">
      {settings[group] &&
        settings[group][setting] &&
        settings[group][setting].collect && (
          <input
            type="checkbox"
            className="checkbox"
            id={`${group}-${setting}-required`}
            onChange={() =>
              onSettingsChange({
                ...settings,
                [group]: {
                  ...settings[group],
                  [setting]: {
                    ...settings[group][setting],
                    required: !settings[group][setting].required,
                  },
                },
              })
            }
            checked={
              (settings[group] &&
                settings[group][setting] &&
                settings[group][setting].required) ||
              false
            }
          />
        )}
      <label
        className={cx("checkbox-label pt-0! p-a l-2 checkbox-label__required", {
          "checkbox-label__required--active":
            settings[group] &&
            settings[group][setting] &&
            settings[group][setting].collect,
        })}
        htmlFor={`${group}-${setting}-required`}
      />
    </td>
  </tr>
)

CollectionField.propTypes = {
  label: PropTypes.string.isRequired,
  setting: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
}
