import React from "react"
import { omit } from "lodash"
import { jqueryDateFormat } from "utils/date_formatting"

class DateInput extends React.Component {
  componentDidMount() {
    const datepicker = $(this.refs.input).datepicker({
      dateFormat: jqueryDateFormat(window.currentOrganization.dateFormat),
      changeMonth: true,
      changeYear: true,
      yearRange: this.props.yearRange,
      onSelect: date => {
        const fakeEvent = {
          target: {
            value: date,
          },
        }
        if (this.props.onChange) {
          this.props.onChange(fakeEvent)
        }
      },
    })
    if (this.props.showOnLoad) {
      datepicker.focus()
    }
  }

  componentWillUnmount() {
    $(this.refs.input).datepicker("destroy")
  }

  render() {
    return (
      <input {...omit(this.props, ["onBlur", "yearRange", "showOnLoad"])} />
    )
  }
}

DateInput.defaultProps = {
  ref: "input",
  type: "text",
  yearRange: "-100:+2",
  showOnLoad: false,
}

export default DateInput
